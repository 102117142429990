import * as React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Container from '../components/container'
import Seo from '../components/seo'

const PrivacyCaliforniaPage = () => (
  <Layout knockoutHeader={false}>
    <Seo
      title='Privacy Notice for California Residents'
      description='This site is operated by Roadster and has been created to provide information about our company, the products and services we provide, and our mobile applications to our Service visitors.'
    />
    <div className='PageHead PageHead--light'>
      <h1 className='PageHead-title'>
        Privacy Notice for California Residents
      </h1>
      <hr className='Divider' />
      <p className='text-sm mb-0'>
        Effective: February 11, 2021
      </p>
    </div>
    <div className='Section Section--condensed'>
      <Container size='sm'>
      <div className='LegalText'>
        <p>
          This PRIVACY NOTICE FOR CALIFORNIA RESIDENTS (“CA Privacy Notice”) supplements the information contained in the Privacy Policy (“we,” “us,” or “our”) and applies solely to visitors, users, and others who reside in the State of California (“California Consumers” or “you”).
        </p>
        <h2>
          I. Information We Collect About Our California Consumers
        </h2>
        <ol style={{ listStyleType: 'upper-alpha' }}>
          <li>
            <p className='font-semi-bold'>
              Types of Information We Collect
            </p>
            <p>
              Roadster is a revolutionary platform for car buying. To maintain our groundbreaking standards and technologies, and to provide you with the best possible service, in the past twelve months we may have collected through the course of our business the following types of information:
            </p>
            <div className='overflow-auto -mx-container sm:mx-0'>
              <table className='Table' style={{ minWidth: '42em' }}>
                <thead>
                  <tr>
                    <th style={{ width: '13em' }}>
                      Category
                    </th>
                    <th>
                      Examples
                    </th>
                    <th>
                      Collected?
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Identifiers
                    </td>
                    <td>
                      A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver's license number, passport number, or other similar identifiers.
                    </td>
                    <td>
                      Yes
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))
                    </td>
                    <td>
                      A name, signature, social security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information. Some personal information included in this category may overlap with other categories.
                    </td>
                    <td>
                      Yes
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Protected classification characteristics under California or federal law
                    </td>
                    <td>
                      Age (40 years or older), race, color, ancestry, national origin, citizenship, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), veteran or military status.
                    </td>
                    <td>
                      Yes
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Commercial information
                    </td>
                    <td>
                      Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.
                    </td>
                    <td>
                      Yes
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Internet or other similar network activity
                    </td>
                    <td>
                      Browsing history, search history, information on interaction with a website, application, or advertisement.
                    </td>
                    <td>
                      Yes
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Geolocation data
                    </td>
                    <td>
                      Physical location or movements.
                    </td>
                    <td>
                      Yes
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Sensory data
                    </td>
                    <td>
                      Audio, electronic, visual, olfactory, or similar information.
                    </td>
                    <td>
                      Yes
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Inferences drawn from other personal information
                    </td>
                    <td>
                      Profile reflecting a person’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
                    </td>
                    <td>
                      Yes
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li>
            <p className='font-semi-bold'>
              How We Collect Information
            </p>
            <p>
              We collect information in a variety of ways, each of which gives us a different opportunity to tailor our services (the “Services”) and provide you with the best experience possible. Our methods include gathering information through forms, applications, surveys, and other online fields offered throughout our Services; in email, text, and other electronic messages between you and us; and when you interact with our advertising and applications on third-party websites and services.
            </p>
          </li>
        </ol>
        <h2>
          II. Your Rights as a California Consumer
        </h2>
        <p>
          As a California resident, you have certain rights regarding your personal information.
        </p>
        <ol style={{ listStyleType: 'upper-alpha' }}>
          <li>
            <p className='font-semi-bold'>
              Opting Out of “Sales”
            </p>
            <p>
              We will not sell your personal information such as name, email address, physical address, or phone number unless you give us permission. However, California law defines “sale” broadly in such a way that the term sale may include using targeted advertising on the Services or other affiliated sites. Like most online publishers and social networks, we may use these services, which enable us to provide the Services and relevant offers to you, including at your request.
            </p>
            <p>
              As such, our use of these services may constitute a California-covered “sale” for the following categories of information to advertising   partners: identifiers such as Internet Protocol address, device IDs, cookie IDs, and advertising IDs; demographic information such as age bracket, commercial information such as the types of products or services you are interested in purchasing.
            </p>
            <p>
              You have the right to direct us not to “sell” your personal information by visiting <Link to='/privacy'>Roadster’s Privacy Policy</Link>.
            </p>
          </li>
          <li>
            <p className='font-semi-bold'>
              Your Access Rights
            </p>
            <p>
              You have the right to request from us the following information: (1) the categories of personal information we have collected about you; (2) the categories of sources from which your personal information is collected; (3) the business or commercial purpose for collecting or selling your personal information; (4) the categories of third parties with whom we share personal information; and (5) the specific pieces of personal information we have collected about you.
            </p>
            <p>
              To the extent that we sold any of your personal information or used any of your personal information for a business person, you have the right to request that we disclose to you: (1) the categories of personal information that we collected about you; (2) the categories of personal information that we sold about you and the categories of third parties to whom the personal information was sold, by category or categories of personal information for each third party to whom the personal information was sold; and (3) the categories of personal information that we disclosed about you for a business purpose.
            </p>
          </li>
          <li>
            <p className='font-semi-bold'>
              Your Deletion Request Rights
            </p>
            <p>
              You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.
            </p>
          </li>
          <li>
            <p className='font-semi-bold'>
              How to Exercise Your Rights
            </p>
            <p>
              To exercise any of the rights listed above, please submit a verifiable consumer request to us by submitting the appropriate request form which can be found on our Privacy Hub. We will only use personal information provided in a consumer request to verify the requestor's identity or authority to make such a request. Please note you may only make a verifiable consumer request for access or data portability twice within a 12-month period.
            </p>
            <p>
              Please note that Roadster must verify the identity of the requestor by requesting your full name, email address and phone number associated with your account, and account number. You may designate an authorized agent to make a request on your behalf by providing proof of a valid power of attorney, your valid government issued identification, and the authorized agent’s valid government issued identification. We cannot respond to requests where the identity and authority of the requestor cannot be confirmed.
            </p>
            <p>
              Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt. If we are unable to comply with a request, we will inform you of the reason why.
            </p>
          </li>
          <li>
            <p className='font-semi-bold'>
              Non-Discrimination Policy
            </p>
            <p>
              California residents that choose to exercise such rights will not be denied any goods or services, charged different prices or rates, or be provided a different level or quality of goods or services unless those differences are related to your personal information.
            </p>
          </li>
          <li>
            <p className='font-semi-bold'>
              Shine the Light Disclosure
            </p>
            <p>
              You may request and obtain from us once a year, free of charge, information about the personal information (if any) we disclosed to third parties for direct marketing purposes in the preceding calendar year.
            </p>
          </li>
        </ol>
        <h2>
          III. Changes to Our Privacy Notice for California Residents
        </h2>
        <p>
          We reserve the right to modify this CA Privacy Notice at any time. If we make changes to this CA Privacy Notice, we will make the modified version available on our Privacy Hub. Your continued use of the Services after any modification to this CA Privacy Notice will constitute your acceptance of such modification.
        </p>
        <h2>
          IV. Contact
        </h2>
        <p>
          If you have any questions about this policy, please contact us in any of the following ways: (1) call us at the following toll-free number: (800) 755- 7355, or (2) send us an email at <a href='mailto:privacy@roadster.com'>privacy@roadster.com</a>.
        </p>
      </div>
      </Container>
    </div>
  </Layout>
)

export default PrivacyCaliforniaPage
